import { loading, okHand } from './icons';

const line = '<hr class="mb-5 border-[#A7E6FF] w-11/12 mx-auto">';

const loadPage = `
  <div class="absolute inset-0 flex justify-center items-center bg-white backdrop-blur-sm bg-opacity-10 z-50">${loading}<div>
`;

const inputWIconLeading = (id, label, name, placeholder, icon, type = 'text') => `
  <div class="mb-5" id="${id}">
    <label for="${name}">${label}</label>
    <div class="relative mt-3 rounded-md shadow-sm">
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">${icon}</div>
      <input type="${type}" name="${name}" id="${name}" class="block w-full rounded-lg border-0 h-12 pl-12 pr-5 text-sm text-[#3572EF] ring-1 ring-inset ring-[#3572EF] placeholder:text-[#3572EF] focus:ring-2 focus:outline-0" placeholder="${placeholder}">
    </div>
  </div>
`;

const inputWIconTrailingCircle = (id, name, placeholder, icon) => `
  <div class="relative mb-3 rounded-full shadow-sm" id="${id}">
    <div id="search-btn" class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer">${icon}</div>
    <input type="text" name="${name}" id="${name}" class="block w-full rounded-full border-0 h-11 pl-5 pr-12 text-sm text-[#3572EF] ring-1 ring-inset ring-[#3572EF] placeholder:text-[#3572EF] focus:ring-2 focus:outline-0" placeholder="${placeholder}">
  </div>
`;

const buttonFilled = (id, text, style) => `
  <button id="${id}" class="bg-[#050C9C] text-white rounded-full w-full h-11 ${style}">${text}</button>
`;

const buttonOutlined = (id, text, style) => `
  <button id="${id}" class="bg-white text-[#050C9C] border border-[#050C9C] rounded-full w-full h-11 ${style}">${text}</button>
`;

const cardReqPayment = (id, nim, nominal, reqOn) => `
  <div class="card-payment mb-3 p-5 pb-3 rounded-lg shadow-[0_2px_4px_#3572EF] cursor-pointer" id="${id}">
    <div class="flex justify-between mb-5">
      <p class="font-bold">${nim}</p>
      <p class="px-3 py-1 rounded bg-[#A7E6FF] text-[#050C9C] text-sm">Request</p>
    </div>
    <div class="flex justify-between mb-3">
      <p class="text-sm">Nominal</p>
      <p class="text-sm">${nominal}</p>
    </div>
    <div class="flex justify-between mb-3">
      <p class="text-sm">Request on</p>
      <p class="text-sm">${reqOn}</p>
    </div>
  </div>
`;

const cardAccPayment = (id, nim, nominal, reqOn, accOn, officer) => `
  <div class="card-payment mb-3 p-5 pb-3 rounded-lg shadow-[0_2px_4px_#3572EF] cursor-pointer" id="${id}">
    <div class="flex justify-between mb-5">
      <p class="font-bold">${nim}</p>
      <div class="flex items-center text-sm">
        <strong class="underline">Accepted</strong>
        <span class="ms-2">${okHand}</span>
      </div>
    </div>
    <div class="flex justify-between mb-3">
      <p class="text-sm">Nominal</p>
      <p class="text-sm">${nominal}</p>
    </div>
    <div class="flex justify-between mb-3">
      <p class="text-sm">Request on</p>
      <p class="text-sm">${reqOn}</p>
    </div>
    <div class="flex justify-between mb-3">
      <p class="text-sm">Accepted on</p>
      <p class="text-sm">${accOn}</p>
    </div>
    <div class="flex justify-between mb-3">
      <p class="text-sm">Officer</p>
      <p class="text-sm">${officer}</p>
    </div>
  </div>
`;

const textInfoOneLine = (title, value) => `
  <div class="flex justify-between mb-5">
    <p class="text-sm">${title}</p>
    <p class="text-sm">${value}</p>
  </div>
`;

const textInfoBoldOneLine = (title, value) => `
  <div class="flex justify-between mb-5">
    <p class="text-sm">${title}</p>
    <p class="text-sm font-bold">${value}</p>
  </div>
`;

export {
  line,
  loadPage,
  inputWIconLeading,
  inputWIconTrailingCircle,
  buttonFilled,
  buttonOutlined,
  cardReqPayment,
  cardAccPayment,
  textInfoOneLine,
  textInfoBoldOneLine,
};
