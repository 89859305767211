import ResetPasswordHandler from '../../handlers/reset-password.handler';
import { iconChevronLeft } from '../templates/icons';

class ResetPassword extends HTMLElement {
  connectedCallback() {
    this.render();
    this.handler();
  }

  render() {
    this.innerHTML = `
      <div id="reset-pass-container" class="m-5 lg:w-2/5 lg:mx-auto">
        <div class="mb-5 flex">
          <button class="pr-4" onclick="location.assign('#/login')">${iconChevronLeft}</button>
          <p>Reset password</p>
        </div>
        <div id="resetpass-form-container"></div>
        <p id="resetpass-timer" class="mt-2 text-center"></p>
      </div>
    `;
  }

  handler() {
    const resetPassContainer = this.querySelector('#reset-pass-container');
    const resetpassFormContainer = this.querySelector('#resetpass-form-container');
    const resetpassTimer = this.querySelector('#resetpass-timer');
    ResetPasswordHandler.init({
      resetPassContainer,
      resetpassFormContainer,
      resetpassTimer,
    });
  }
}

customElements.define('reset-password-page', ResetPassword);
const resetPassword = '<reset-password-page></reset-password-page>';
export default resetPassword;
